import React from 'react';
import { connect } from 'react-redux';

import { ordersFindSetFilterSubjectGroup } from 'store/ordersFind/actions';
import { getOrdersSubjectsByGroupWithCount } from 'store/ordersFind/selectors';


import CommonSearchForm from './CommonSearchForm';

const SubjectsSearchForm = (props) => {
  const {
    filter, orderSubjects, setFilterSubject, isDe = false,
  } = props;

  const onChangeHandler = (event) => {
    const { name, checked } = event.target;
    if (checked) setFilterSubject([...filter.subject, ...[event.target.name]]);
    else setFilterSubject(filter.subject.filter(subject => subject !== name));
  };

  return (
    <CommonSearchForm
      mobile
      orderTypes={orderSubjects}
      onChange={onChangeHandler}
      checked={filter.subject_group}
      isDe={isDe}
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  const filter = state.ordersFind.filter[ownProps.typeId];
  const orderSubjects = getOrdersSubjectsByGroupWithCount[ownProps.typeId](state);
  return {
    filter,
    orderSubjects,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  setFilterSubject: value => dispatch(ordersFindSetFilterSubjectGroup(ownProps.typeId, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubjectsSearchForm);
