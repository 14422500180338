import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { withRouter } from 'hocs';

import OrderCard from 'components/OrderCard';
import CommonLoading from 'components/CommonLoading';
import NoOrders from 'components/NoOrders';
import OrderTableNotifications from 'components/OrderTableNotifications';

import { userSetShowProposed } from 'store/user/actions';
import { ordersFindFetchIfNeeded,
  ORDERS_TYPE_RESERVED, ORDERS_TYPE_ALL, ORDERS_TYPE_FAVORITE, ordersFindSetSorting,
} from 'store/ordersFind/actions';
import { getOrdersFilteredSorted } from 'store/ordersFind/selectors';
import { getNewNotViewedOrderNotifications } from 'store/ordersNotifications/selectors';

import { clearNewOrdersNotifications } from 'store/ordersNotifications/actions';
import { setFilteredOrderList } from 'store/ordersFilteredFind/actions';

import utils from 'utils';
import classNames from 'utils/classNames';
import { isNewbie } from 'utils/groups';
import storage from 'utils/localStorageHandler';

import { MAX_VIEW_ITEMS } from 'constants';

import './styles/index.scss';

const ORDERS_TYPES = {
  '/findorders/all': ORDERS_TYPE_ALL,
  '/findorders/saved': ORDERS_TYPE_FAVORITE,
  '/findorders/reserved': ORDERS_TYPE_RESERVED,
};

class OrdersCards extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.handleMove = this.handleMove.bind(this);
    this.onOpenOrderWheel = this.onOpenOrderWheel.bind(this);
  }

  componentDidMount() {
    const { loadOrders, orderList, isLoading } = this.props;
    loadOrders();
    window.addEventListener('scroll', this.handleMove);
    if (orderList) {
      this.props.setFilteredOrderList(orderList.filter(o => !o.is_pinned && !o.reserved_by_me).map(o => o.number));
    }

    if (orderList.length > 0 && !isLoading) {
      setTimeout(() => {
        const isUserViewedCountChange = storage.get('userViewedCountChange') || false;
        if (isUserViewedCountChange) {
          window.dispatchEvent(new Event('storage'));
        }
      }, 1);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.orderList.length !== this.props.orderList.length) {
      const fOrders = this.props.orderList.filter(o => !o.is_pinned && !o.reserved_by_me);
      if (fOrders.length === 0 || prevProps.orderList.length === 0) {
        this.props.setFilteredOrderList(fOrders.map(o => o.number));
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleMove);
  }

  onClick(id) {
    const { history } = this.props;
    history.push(`/order/${id}`);
  }

  onOpenOrderWheel() {
    const { userSetShowProposedProp } = this.props;
    userSetShowProposedProp(true);
  }

  handleMove() {
    const { orderList, pinnedOrders } = this.props;
    if (!window.$headRef) return;

    const headRef = window.$headRef;

    if (orderList.length < 5) return;

    const fiveOrdersBlock = (123 * (pinnedOrders.length + 1)) - 48;

    if (document.documentElement.scrollTop > fiveOrdersBlock) {
      headRef.classList.add('sticky-table-head-fixed');
    } else {
      headRef.classList.remove('sticky-table-head-fixed');
    }
  }

  loadMore() {
    if (window.innerHeight + document.documentElement.scrollTop >= document.scrollingElement.scrollHeight - 50) {
      const { orderList } = this.props;
      this.setState(state => Object.assign({}, state, {
        count: Math.min(state.count + MAX_VIEW_ITEMS, orderList.length),
      }));
    }
  }

  renderCard(order) {
    const { profile } = this.props;

    const {
      reserved = false, ready_reserved = false, reserved_by_me = false, number: _number,
    } = order;
    const isOtherWriterReserve = (reserved || ready_reserved) && !reserved_by_me;
    return (
      <OrderCard key={order._id} number={_number} profile={profile} onClick={(number, id) => this.onClick(number, id)} isOtherWriterReserve={isOtherWriterReserve} />
    );
  }

  renderOrderList() {
    const { orderList, isLoading, allOrders } = this.props;

    if (isLoading) {
      return (<CommonLoading />);
    }
    if (!orderList.length) {
      return (<NoOrders option={!allOrders.length ? 'noFilters' : 'withFilters'} />);
    }
    return orderList.map(order => this.renderCard(order));
  }

  render() {
    const {
      notViewedNewOrders = [], isLoading, orderList, setSorting, sorting, isStem,
      isNewbieGroup, isDe,
    } = this.props;
    const sortingFields = [
      {
        field: 'writer_deadline',
        title: 'Deadline',
      },
    ];
    sortingFields.unshift(
      {
        field: isStem ? 'pages' : 'writer_price',
        title: 'Price',
      },
      {
        field: 'pages',
        title: 'Size',
      },
    );

    return (
      <Fragment>
        {
          !isLoading && orderList.length > 0 && (
          <div className="sorting-block">
            {
              !isNewbieGroup && !isDe ?
              (<div className="sorting-block__item sorting-block__item--fill" onClick={this.onOpenOrderWheel}>Find order</div>) :
              <Fragment>Sort by</Fragment>
            }
            <div className="sorting-items">
              {
              sortingFields.map(({ field, title }) => {
                const sortingClass = classNames('sorting-block__item', {
                  'sorting-block__item--active': field === sorting.field,
                  isAsc: sorting.isAsc,
                });
                return (
                  <div className={sortingClass} key={field} onClick={() => setSorting(field)}>{title}</div>
                );
              })
            }
            </div>
          </div>
          )
        }
        <div className="orders-cards find-orders" ref={ref => window.$headRef = ref}>
          {this.renderOrderList()}
          {notViewedNewOrders.length > 0 && <OrderTableNotifications notViewedNewOrders={notViewedNewOrders} />}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { location } = ownProps;
  const orderType = ORDERS_TYPES[location.pathname] || ORDERS_TYPE_ALL;
  const orderList = getOrdersFilteredSorted[orderType](state);
  const { sorting, isFetching: isLoading, numbers: allOrders = [] } = state.ordersFind;
  const isStem = utils.isStem(state.user);
  const isDe = utils.isDe(state.user);
  const group = (state.user && state.user.qc_group && state.user.qc_group.toLowerCase()) || '';
  const isNewbieGroup = isNewbie(group);

  return {
    orderList,
    isLoading,
    profile: state.user,
    notViewedNewOrders: getNewNotViewedOrderNotifications(state) || [],
    orderType,
    sorting,
    pinnedOrders: orderList.filter(order => order.is_pinned),
    isStem,
    isNewbieGroup,
    isDe,
    allOrders,
  };
};

const mapDispatchToProps = dispatch => ({
  loadOrders: () => dispatch(ordersFindFetchIfNeeded()),
  setSorting: (field) => {
    dispatch(clearNewOrdersNotifications());
    dispatch(ordersFindSetSorting(field));
  },
  setFilteredOrderList: orderList => dispatch(setFilteredOrderList(orderList, ORDERS_TYPE_ALL)),
  userSetShowProposedProp: value => dispatch(userSetShowProposed(value, 'lucky_btn')),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrdersCards));
