import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import { withPathNoRender } from 'hocs';

import Icon from 'components/Icon';
import classNames from 'utils/classNames';
import utils from 'utils';

import { getHasUnreadMessages } from 'store/notification/selectors';

import './styles/menu.mobile.scss';

const MainNav = (props) => {
  const { hasNewMessage, profile } = props;
  const ordersClassName = classNames({ 'new my-order': hasNewMessage });
  const isStem = utils.isStem(profile);
  return (
    <div className="menu menu--bottom">
      <ul>
        <li>
          <NavLink to="/findorders">
            <Icon className="svg-icon" iconName="search" />
            <div className="fsSm">Orders</div>
          </NavLink>
        </li>
        <li>
          <NavLink to="/myorders" className={ordersClassName}>
            <Icon className="svg-icon" iconName="papers-with-tab" />
            <div className="fsSm">My Orders</div>
          </NavLink>
        </li>
        {!isStem &&
          <li>
            <NavLink to="/finances">
              <Icon className="svg-icon" iconName="finances" />
              <div className="fsSm">Finances</div>
            </NavLink>
          </li>
        }
        <li>
          <NavLink to="/profile">
            <Icon className="svg-icon" iconName="user2" />
            <div className="fsSm">Profile</div>
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

const mapStateToProps = (state) => {
  const hasNewMessage = getHasUnreadMessages(state);
  return {
    hasNewMessage,
    profile: state.user,
  };
};

export default withPathNoRender(connect(mapStateToProps)(MainNav), 'order/');
