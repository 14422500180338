import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import CommonLoading from 'components/CommonLoading';
import Dropdown from 'components/Dropdown';
import Select from 'components/Select';

import { userSetSubjectsFetch } from 'store/user/actions';

import './styles/index.scss';

const SUBJECTS_DROP_LABELS = ['Primary', 'Additional #1', 'Additional #2'];

const PreferredSubjects = ({ isEditable }) => {
  const { orderSubjectGroups, isFetching } = useSelector(state => state.settings);
  const subjectList = useMemo(() => Object.keys(orderSubjectGroups).filter(s => s.toLowerCase() !== 'other').sort(), [orderSubjectGroups]);
  const selectedSubjects = useSelector(state => state.user.subjects || []).filter(subject => subjectList.indexOf(subject) > -1);

  const subjectList1 = useMemo(() => subjectList.filter(subject => subject !== selectedSubjects[1] && subject !== selectedSubjects[2]), [selectedSubjects]);
  const subjectList2 = useMemo(() => subjectList.filter(subject => subject !== selectedSubjects[0] && subject !== selectedSubjects[2]), [selectedSubjects]);
  const subjectList3 = useMemo(() => subjectList.filter(subject => subject !== selectedSubjects[0] && subject !== selectedSubjects[1]), [selectedSubjects]);

  const dispatch = useDispatch();

  const onChange = useCallback(
    (value, index) => {
      if (selectedSubjects.includes(value)) return;

      dispatch(userSetSubjectsFetch(selectedSubjects.map((subject, __index) => {
        if (__index === index) return value;
        return subject;
      })));
    },
    [dispatch, selectedSubjects],
  );

  const renderDropdown = (subjectGroup, index) => (
    <div key={SUBJECTS_DROP_LABELS[index]} className="mb15">
      <h2 className="preferred-subjects__dropdown-title fs15 mb8">{ SUBJECTS_DROP_LABELS[index] }</h2>
      <Dropdown disabled={!isEditable} text={selectedSubjects[index]} closeAlways>
        <Select className="menu menu-selectable" value={selectedSubjects[index]} onChange={value => onChange(value, index)}>
          {subjectGroup.map(subject => <li className="menu-item subjects-group-form__dropdown_items" key={subject} value={subject}>{subject}</li>)}
        </Select>
      </Dropdown>
    </div>
  );

  if (isFetching) return (<CommonLoading />);
  return (
    <div className="preferred-subjects">
      <div className="preferred-subjects__header">
      Please select three subject categories that you would like to complete orders in. Our team leaders will recommend you orders according to your preferences.
      </div>
      {[subjectList1, subjectList2, subjectList3].map((__subjectList, index) => (
        renderDropdown(__subjectList, index)
      ))}
    </div>
  );
};

PreferredSubjects.displayName = 'Preferred subjects';

export default PreferredSubjects;
