import React from 'react';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';

import { getDiffBetweenServerClientTime } from 'store/settings/selectors';

import classNames from 'utils/classNames';
import formatTime from 'utils/formatTime';
import calcNowTime from 'utils/calcNowTime';

export default function OrderDeadline({ writer_deadline }) {
  const diffBetweenServerClientTime = useSelector(state => getDiffBetweenServerClientTime(state));

  const momentDeadLine = moment(writer_deadline);
  const hoursDiff = Math.round(momentDeadLine.diff(calcNowTime(diffBetweenServerClientTime), 'hours', true));

  if (hoursDiff < 0 || hoursDiff >= 96) {
    return (<span>{formatTime(momentDeadLine, 'dt')}</span>);
  }
  const className = classNames({ 'text-warning': hoursDiff < 24 });
  return (
    <span>
      {`${formatTime(momentDeadLine, 'dt')} / `}
      <b className={className}>{hoursDiff}h</b>
    </span>
  );
}
