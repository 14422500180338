import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

import Radio from 'components/Radio';

import { ordersCompleteSetFilterTime, ordersCompleteResetFilter } from 'store/ordersComplete/actions';

import { ordersRefundedSetFilterTime, ordersRefundedResetFilter } from 'store/ordersRefunded/actions';


class MyOrdersFilter extends Component {
  componentDidUpdate(prevProps) {
    if (prevProps.reset !== this.props.reset) {
      this.props.resetFilter();
    }
  }

  render() {
    const {
      filterTimesLong, filter, setFilterTime, translate,
    } = this.props;
    return (
      <div className="orders-filter-container">
        <ul className="container-item">
          <h3>Deadlines</h3>
          {filterTimesLong.map(item => (
            <Radio key={item} name="deadlinesFilter" value={item} checked={!filter.time.length && item === 'all' ? true : filter.time.includes(item)} onChange={setFilterTime}>
              {translate(`timeFilter.${item}`)}
            </Radio>
          ))}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const refundedMode = ownProps.location.pathname === '/myorders/refunded';
  const translate = getTranslate(state.locale);
  const filter = refundedMode ? state.ordersRefunded.filter : state.ordersComplete.filter;
  const { filterTimesLong } = state.settings;
  return { filter, filterTimesLong, translate };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const refundedMode = ownProps.location.pathname === '/myorders/refunded';
  return {
    setFilterTime: ({ target }) => dispatch(refundedMode ? ordersRefundedSetFilterTime(target.value) : ordersCompleteSetFilterTime(target.value)),
    resetFilter: () => dispatch(refundedMode ? ordersRefundedResetFilter() : ordersCompleteResetFilter()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyOrdersFilter);
