import React from 'react';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import { Provider } from 'react-redux';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import utils from 'utils';

import { CalcHeightContextProvider } from 'components/order/CalcHeightContext';

import LoginPage from './pages/LoginPage';
import MyOrdersPage from './pages/mobile/MyOrdersPage';
import FinancesPage from './pages/mobile/FinancesPage';
import OrderStandalonePage from './pages/OrderStandalonePage';
import ProfilePage from './pages/mobile/ProfilePage';

import Agreement from './components/agreement/Agreement';
import DataPreload from './components/DataPreload';
import FrozenModal from './components/profile/FrozenModal';
import Header from './components/Header';
import MainNav from './components/MainNav/MainNav.mobile';
import ModalContainer from './components/ModalContainer';
import NotFoundPage from './components/NotFoundPage/NotFoundPage.mobile';
import NotConditionUserPage from './components/NotFoundPage/NotConditionUserPage.mobile';
import SessionExtendModal from './components/SessionExtendModal';
import SuspiciousAlert from './components/SuspiciousAlert';
import WriterPoll from './components/WriterPoll';
import LRPixel from './components/LRPixel';
import TutorRecruitingBanner from './components/TutorRecruitingBanner';
import VerifyForm from './components/VerifyForm';
import WarBanner from './components/WarBanner';
import OrdersCards from './components/OrdersCards';

import Xmpp from './Xmpp';

import './index.mobile.scss';

utils.updateCookie('DESKTOP', '', 0);

const WrappedStandalonePage = props => (<CalcHeightContextProvider><OrderStandalonePage mobile {...props} /></CalcHeightContextProvider>);

const LoggedContainer = () => (
  <DataPreload id="app">
    <SuspiciousAlert />
    <ModalContainer isMobile />
    <LRPixel />
    <Header />
    <Routes>
      <Route path="*" element={<Navigate to="findorders/all" />} />
      <Route path="/" element={<Navigate to="findorders/all" />} />
      <Route path="order/:id" element={<WrappedStandalonePage />} />
      <Route path="findorders/all" element={<OrdersCards />} />
      <Route path="myorders/*" element={<MyOrdersPage />} />
      <Route path="finances" element={<FinancesPage />} />
      <Route path="profile" element={<ProfilePage />} />
      <Route path="progress/*" element={<NotFoundPage />} />
      <Route path="inbox/*" element={<NotFoundPage />} />
      <Route path="issues/*" element={<NotFoundPage />} />
      <Route path="help/*" element={<NotFoundPage />} />
    </Routes>
    <Agreement isMobile />
    <SessionExtendModal />
    <FrozenModal />
    <NotConditionUserPage />
    <WriterPoll />
    <TutorRecruitingBanner />
    <WarBanner />
    <VerifyForm />
  </DataPreload>
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  },
});

const App = props => (
  <Provider store={props.store}>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Xmpp>
          <div id="router">
            <Routes>
              <Route path="*" element={<LoggedContainer />} />
              <Route path="login/:token" element={<LoginPage />} />
            </Routes>
            <MainNav />
          </div>
        </Xmpp>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </BrowserRouter>
  </Provider>
);

export default App;
