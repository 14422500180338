/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withRouter } from 'hocs';

import { financesFetchIfNeeded } from 'store/finances/actions';
import { getFinancesByFilterSorted } from 'store/finances/selectors';

import InfoVerifyContent from 'components/agreement/InfoVerifyContent';

import FinanceCard from 'components/FinanceCard';
import CommonLoading from 'components/CommonLoading';
import NoFinances from 'components/NoFinances';
import FinancesModal from 'components/FinancesModal';
import FinancesCancelModal from 'components/FinancesCancelModal';
import FinancesRequestToPassModal from 'components/FinancesRequestToPassModal';

import classNames from 'utils/classNames';
import getVerifyStatusText from 'utils/getVerifyStatusText';

import { MAX_VIEW_ITEMS } from 'constants';

import './styles/financePage.scss';

class FinancesPage extends Component {
  static renderCard(finance) {
    return (
      <FinanceCard id={finance._id} key={finance._id} />
    );
  }
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
      isModalInfoVerifyOpen: false,
      count: MAX_VIEW_ITEMS,
      isModalRequestToPassOpen: false,
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.toggleWithdrawal = this.toggleWithdrawal.bind(this);
    this.toggleModalRequestToPassOpenModal = this.toggleModalRequestToPassOpenModal.bind(this);
  }
  componentDidMount() {
    const { loadFinances } = this.props;
    loadFinances();
    window.addEventListener('scroll', this.loadMore);
  }
  componentDidUpdate(prevProps) {
    const { loadFinances } = this.props;
    loadFinances();
    if (prevProps.isFetchingWithdrawal && !this.props.isFetchingWithdrawal && !this.props.withdrawalErrorMessage && this.props.execution === 0) {
      this.toggleModal();
    }
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.loadMore);
  }

  loadMore() {
    if (window.innerHeight + document.documentElement.scrollTop === document.scrollingElement.scrollHeight) {
      const { financeList } = this.props;
      this.setState(state => Object.assign({}, state, {
        count: Math.min(state.count + MAX_VIEW_ITEMS, financeList.length),
      }));
    }
  }

  toggleModal() {
    this.setState(state => Object.assign({}, state, {
      isModalOpen: !state.isModalOpen,
    }));
  }

  toggleModalRequestToPassOpenModal() {
    this.setState(state => Object.assign({}, state, {
      isModalRequestToPassOpen: !state.isModalRequestToPassOpen,
    }));
  }

  toggleWithdrawal() {
    const { profile } = this.props;
    const { last_veriff_session_object: veriffSession = {}, otp_status: otpStatus } = profile;

    if (otpStatus === 'Not Enrolled') {
      this.toggleModalRequestToPassOpenModal();
      return;
    }
    if (veriffSession.status === 'approved' || veriffSession.status === 'not_required') {
      this.toggleModal();
      return;
    }

    this.setState(state => ({ ...state, isModalInfoVerifyOpen: !state.isModalInfoVerifyOpen }));
  }

  renderFinanceList() {
    const {
      financeList, isLoading, pending, available, execution, profile,
      payMethod, allowWithdrawal, pendingWithdrawal,
    } = this.props;
    const {
      isModalOpen, count, isModalInfoVerifyOpen, isModalRequestToPassOpen,
    } = this.state;
    const availableClassName = classNames({ 'text-warning': available <= 0 });

    const { last_veriff_session_object: veriffSession = {} } = profile;

    const isDisabledByPayoneer = payMethod === 'PayoneerEmail' && (available / 100) < 50;

    if (isLoading) {
      return (<CommonLoading />);
    }
    if (!financeList.length) {
      return (<NoFinances />);
    }
    return (
      <div>
        <div className="finance-page-stat-block">
          <div className="mb8 finance-page-stat-block__head-block fsXL">Profile status: <b>{getVerifyStatusText(veriffSession.status)}</b></div>
          <div className="mb8 finance-page-stat-block__head-block fsXL">Pending balance: <b>${(pending / 100).toFixed(2)}</b></div>
          <div className="mb8 finance-page-stat-block__head-block fsXL">Available balance: <b className={availableClassName}>${(available / 100).toFixed(2)}</b></div>
          {allowWithdrawal ? (
            <div className="tooltip tooltip-left minWidth100">
              <button
                className="btn btn-bright btn-block tooltip-trigger"
                type="button"
                disabled={isLoading || (available === 0 && pending === 0 && execution === 0) || veriffSession.status === 'declined' || isDisabledByPayoneer}
                onClick={this.toggleWithdrawal}
              >
                Request withdrawal
              </button>
              {isDisabledByPayoneer && (
                <div className="tooltip-content">
                  Unfortunately, you need to have at least $50 on your available balance to request withdrawal to Payoneer.
                </div>
              )}
            </div>
          ) : (
            <div className="tooltip tooltip-left minWidth100">
              <button
                className="btn btn-bright btn-block tooltip-trigger"
                type="button"
                disabled
              >
                Request withdrawal
              </button>
              {pendingWithdrawal &&
              <div className="tooltip-content">
                Please wait for your pending withdrawal request to be processed before you can create a new one.
              </div>
                }
            </div>
          )}
        </div>
        <div className="table-note vertical">
          <i className="icon_info" />
          <p>Note: financial transactions can take up to 24 hours to be processed and appear in the list.</p>
        </div>
        {financeList.slice(0, count || MAX_VIEW_ITEMS).map(finance => FinancesPage.renderCard(finance))}
        {
          execution === 0
            ? <FinancesModal isOpen={isModalOpen} toggleModal={this.toggleModal} />
            : <FinancesCancelModal isOpen={isModalOpen} toggleModal={this.toggleModal} />
        }
        {isModalInfoVerifyOpen && <InfoVerifyContent noBtn={veriffSession.status === 'submitted'} modalName={veriffSession.status === 'submitted' ? 'VerificationWithdrawalInProgress' : 'VerificationWithdrawal'} onCloseModal={this.toggleWithdrawal} />}
        {isModalRequestToPassOpen &&
          <FinancesRequestToPassModal toggleModal={this.toggleModalRequestToPassOpenModal} />
        }
      </div>
    );
  }

  render() {
    return (
      <div className="orders-cards">
        {this.renderFinanceList()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const financeList = getFinancesByFilterSorted(state);
  const {
    available,
    pending,
    completed,
    execution,
    isFetching: isLoading,
    isFetchingWithdrawal,
    withdrawalErrorMessage,
    allow_withdrawal: allowWithdrawal,
    pending_withdrawal: pendingWithdrawal,
  } = state.finances;
  return {
    financeList,
    isLoading,
    pending,
    available,
    execution,
    completed,
    profile: state.user,
    isFetchingWithdrawal,
    withdrawalErrorMessage,
    allowWithdrawal,
    payMethod: state.user?.pay_method,
    pendingWithdrawal,
  };
};

const mapDispatchToProps = dispatch => ({
  loadFinances: () => dispatch(financesFetchIfNeeded()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FinancesPage));
