import React, { Component } from 'react';
import { connect } from 'react-redux';
import { UnmountClosed } from 'react-collapse';

import Icon from 'components/Icon';
import PayloadDropDown from 'components/PayloadDropDown';

import { financeSetViewedFetch } from 'store/finances/actions';

import classNames from 'utils/classNames';
import formatTime from 'utils/formatTime';
import amountToString from 'utils/amountToString';
import displayFinanceTarget from 'utils/displayFinanceTarget';

import './styles/index.scss';

class FinanceCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
    this.onClick = this.onClick.bind(this);
  }
  onClick() {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  }

  render() {
    const { finance, setViewed } = this.props;
    const { isOpen } = this.state;
    const {
      payload, target, mark, amount, title, status_display, created_at,
    } = finance;
    const unread = target === 'bonus' && mark === 'unread';
    const cardClassName = classNames('finance-card', { 'not-read': unread }, { 'is-success': amount > 0 });
    const collapseLinkClassName = classNames('lnk-collapse', { active: isOpen });
    const tagName = displayFinanceTarget(finance);
    const tagClass = classNames('finance-card-tag-date-block__tag', { 'finance-card-tag-date-block__tag--payment': tagName === 'Payment' });
    return (
      <div className={cardClassName}>
        <div className="finance-card-tag-date-block">
          <div className={tagClass}>{tagName}</div>
          <div>{formatTime(created_at, 'd')}</div>
        </div>
        <div className="finance-card__title mb10">{!payload ? title : <a className={collapseLinkClassName} onClick={this.onClick}>{title}</a>}</div>
        {!!payload &&
          <UnmountClosed isOpened={isOpen}>
            <PayloadDropDown payload={payload} />
          </UnmountClosed>
        }
        <div className="status-container mb10">
          <div className="status-container__icon-status">
            <Icon className="svg-icon mr8" iconName="combined-shape" />
            <div className="status-container__status mr10">{status_display}</div>
            <div className="status-container__status mr10">{amountToString(finance.amount)}</div>
          </div>
          {unread &&
            <button type="button" className="btn btn-light btn-small custom-hover" disabled={finance.isFetchingViewed} onClick={setViewed}>Mark as read</button>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const finance = state.finances.items[ownProps.id];
  return { finance };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  setViewed: () => dispatch(financeSetViewedFetch(ownProps.id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FinanceCard);
