import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

import Dropdown from 'components/Dropdown';
import Select from 'components/Select';

import { getFinanceTypes, getFinanceStatuses } from 'store/finances/selectors';

import {
  financesSetFilterTime,
  financesSetFilterType,
  financesSetFilterStatus,
  financesResetAllFilters,
} from 'store/finances/actions';

class FinancesFilter extends Component {
  componentDidUpdate(prevProps) {
    if (prevProps.reset !== this.props.reset) {
      this.props.financesResetAllFilters();
    }
  }

  render() {
    const {
      filterTimesLong, filter, setFilterTime, translate, filterTypes, setFilterType, filterStatuses, setFilterStatus,
    } = this.props;
    return (
      <div>
        <div className="mb20">
          <p className="pb8 mb8 with-border-bottom">Time Filters</p>
          <Dropdown text={translate(`timeFilter.${filter.time}`)} closeAlways>
            <Select className="menu menu-selectable" onChange={setFilterTime} value={filter.time}>
              {filterTimesLong.map(item =>
                <li key={item} value={item} className="menu-item">{translate(`timeFilter.${item}`)}</li>)}
            </Select>
          </Dropdown>
        </div>
        <div className="mb20">
          <p className="pb8 mb8 with-border-bottom">Type Filters</p>
          <Dropdown text={translate(`financeTypeFilter.${filter.type}`)} closeAlways>
            <Select className="menu menu-selectable" onChange={setFilterType} value={filter.type}>
              {filterTypes.map(item =>
                <li key={item} value={item} className="menu-item">{translate(`financeTypeFilter.${item}`)}</li>)}
            </Select>
          </Dropdown>
        </div>
        <div>
          <p className="pb8 mb8 with-border-bottom">Status Filters</p>
          <Dropdown text={translate(`financeStatusFilter.${filter.status}`)} closeAlways>
            <Select className="menu menu-selectable" onChange={setFilterStatus} value={filter.status}>
              {filterStatuses.map(item =>
                <li key={item} value={item} className="menu-item">{translate(`financeStatusFilter.${item}`)}</li>)}
            </Select>
          </Dropdown>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const translate = getTranslate(state.locale);
  const { filterTimesLong } = state.settings;
  const filterTypes = getFinanceTypes(state);
  const filterStatuses = getFinanceStatuses(state);
  const {
    filter,
  } = state.finances;
  return {
    filter,
    filterTimesLong,
    filterTypes,
    filterStatuses,
    translate,
  };
};

const mapDispatchToProps = dispatch => ({
  setFilterTime: value => dispatch(financesSetFilterTime(value)),
  setFilterType: value => dispatch(financesSetFilterType(value)),
  setFilterStatus: value => dispatch(financesSetFilterStatus(value)),
  financesResetAllFilters: value => dispatch(financesResetAllFilters(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FinancesFilter);
