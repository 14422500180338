import React, { Component } from 'react';
import { connect } from 'react-redux';

import { userFetchIfNeeded } from 'store/user/actions';

import CommonLoading from 'components/CommonLoading';
import Profile from 'components/profile/Profile.mobile';

class ProfilePage extends Component {
  constructor(props) {
    super(props);
    this.renderProfile = this.renderProfile.bind(this);
  }
  componentDidMount() {
    const { load } = this.props;
    load();
  }
  renderProfile() {
    const { isLoading, profile } = this.props;

    if (isLoading) return (<CommonLoading />);

    return (
      <Profile profile={profile} />
    );
  }
  render() {
    return (
      <div className="profile-page">
        {this.renderProfile()}
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  profile: user,
  isLoading: user.isLoading,
});

const mapDispatchToProps = dispatch => ({
  load: () => dispatch(userFetchIfNeeded()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
