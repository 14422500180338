import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import utils from 'utils';
import logout from 'utils/logout';
import classNames from 'utils/classNames';
import getVerifyStatusText from 'utils/getVerifyStatusText';

import { addModalComponent } from 'store/modals/actions';
import { addVerifyModal } from 'store/verifyModal/actions';

import PreferredSubjects from 'components/PreferredSubjects';

import VERIFY_STATUSES from 'constants/verifyStatuses';

import { MySubjectsForm } from './Forms';
import { DateOfBirthDropdown, TimezoneDropdown } from './Controls';

import './styles/mobile/index.scss';

const getDeStatus = state => state.user && (state.user.profile_type === 'D');

const Profile = ({ profile }) => {
  const isDe = useSelector(getDeStatus);

  const dispatch = useDispatch();

  const { last_veriff_session_object: veriffSession = {} } = profile;

  const [isLoadingVerifyStatus, toggleLoadingVerifyStatus] = useState(false);

  const isStem = utils.isStem(profile);

  let veriffSessionStatus = veriffSession.status;

  if (veriffSession.active_status === 'declined' && veriffSession.crm_status === 'Resubmission') {
    veriffSessionStatus = 'resubmission_requested';
  }

  const verifyStatus = VERIFY_STATUSES[veriffSessionStatus] || VERIFY_STATUSES.default;


  const addModal = () => dispatch(addModalComponent(isDe ? <MySubjectsForm isEditable={!isStem} isMobile /> : <PreferredSubjects isEditable={!isStem} />));
  const toggleVerifyModalHandler = () => {
    if (veriffSession.status === 'resubmission_requested' && veriffSession.url) {
      window.open(veriffSession.url, '_blank').focus();
      return;
    }

    dispatch(addVerifyModal({
      verifyStatusHandler: toggleLoadingVerifyStatus,
    }));
  };

  return (
    <div>
      <div className="person-common-info">
        <div className="personal-info-item">
          <div className="personal-info-item__title">Profile status:</div>
          <p className="personal-info-item__value as-grey-text">
            {getVerifyStatusText(veriffSession.status)}
          </p>
        </div>
        {
          profile.birth_day && <div className="personal-info-item">
            <DateOfBirthDropdown
              value={profile.birth_day}
            />
            </div>
        }
        {
          profile.public_degree && <div className="personal-info-item">
            <div className="personal-info-item__title">Degree:</div>
            <p className="personal-info-item__value">
              {profile.public_degree}
            </p>
            </div>
        }
        {
          !(veriffSession.status === 'not_required' || veriffSession.status === 'approved') && (
            <button
              className={classNames('btn btn-100-4 verify-btn', verifyStatus.class || 'btn-bright')}
              onClick={toggleVerifyModalHandler}
              disabled={verifyStatus.disabled || false}
            >
                {isLoadingVerifyStatus ? 'Loading ...' : verifyStatus.text}
            </button>
          )
        }
      </div>
      {
        profile.public_overview && <div className="person-common-info">
          <div className="person-common-info-title mb8">Overview</div>
          <p className="personal-info-item__value personal-info-item__value--black">
            {profile.public_overview}
          </p>
          </div>
      }
      <div className="person-common-info">
        <div className="person-common-info-title mb8">Education</div>
        {
          profile.university && <div className="personal-info-item">
            <div className="personal-info-item__title">University:</div>
            <p className="personal-info-item__value">
              {profile.university}
            </p>
          </div>
          }
        {
          profile.profile_involvement && <div className="personal-info-item">
            <div className="personal-info-item__title">Area of involvement:</div>
            <p className="personal-info-item__value">
              {profile.profile_involvement}
            </p>
          </div>
        }
        <div className="personal-info-item" onClick={addModal}>
          <div className="personal-info-item__title">Preferred subjects:</div>
          <p className="personal-info-item__value">
            {(profile.subjects).join(', ') || 'Not specified'}
          </p>
        </div>
        {
          profile.major && <div className="personal-info-item">
            <div className="personal-info-item__title">Major:</div>
            <p className="personal-info-item__value">
              {profile.major}
            </p>
          </div>
          }
      </div>
      <div className="person-common-info">
        <div className="person-common-info-title mb8">Location</div>
        {
          profile.country && <div className="personal-info-item">
            <div className="personal-info-item__title">Country:</div>
            <p className="personal-info-item__value">
              {profile.country}
            </p>
          </div>
          }
        {
          profile.public_city && <div className="personal-info-item">
            <div className="personal-info-item__title">City:</div>
            <p className="personal-info-item__value">
              {profile.public_city}
            </p>
          </div>
          }
        {
            profile.timezone_str && <div className="personal-info-item personal-info-item__time-zone">
              <TimezoneDropdown
                value={profile.timezone_str}
              />
              </div>
          }
      </div>
      <div className="person-common-info">
        <div className="person-common-info-title mb8">Contact info</div>
        {
          profile.email && <div className="personal-info-item">
            <div className="personal-info-item__title">Email:</div>
            <p className="personal-info-item__value personal-info-item__value--no-margin">
              {profile.email}
            </p>
            </div>
          }
        {
          profile.phone && <div className="personal-info-item">
            <div className="personal-info-item__title">Phone:</div>
            <p className="personal-info-item__value personal-info-item__value--no-margin">
              {profile.phone}
            </p>
            </div>
          }
      </div>
      <div className="person-common-info">
        <div className="profile-page__logout-block" onClick={logout}>
          Logout
          <i className="exit-icon" />
        </div>
      </div>
    </div>
  );
};

export default Profile;
