import React, { Component } from 'react';
import { connect } from 'react-redux';

import { deleteAllModalComponent } from 'store/modals/actions';

import OrdersMobileFilter from 'components/OrdersFilter/mobileView';

import OrdersFilters from './OrdersFilters';
import FinancesFilter from './FinancesFilter';
import MyOrdersFilter from './MyOrdersFilter';

import './styles/index.scss';

class Filters extends Component {
  constructor(props) {
    super(props);
    this.location = props.location;
    this.state = {
      reset: false,
    };
    this.backResetHandler = this.backResetHandler.bind(this);
  }
  backResetHandler() {
    this.setState({ reset: false });
  }

  filterBody(type) {
    const { reset } = this.state;
    const { isDe } = this.props;
    switch (type) {
      case 'findorders':
        if (isDe) {
          return <OrdersFilters location={this.location} reset={reset} />;
        }
        return <OrdersMobileFilter reset={reset} backReset={this.backResetHandler} />;
      case 'finances':
        return <FinancesFilter reset={reset} />;
      case 'myorders':
        return <MyOrdersFilter location={this.location} reset={reset} />;
      default:
        return null;
    }
  }
  render() {
    const { deleteModal } = this.props;
    const { reset } = this.state;
    return (
      <div className="filter-container">
        {this.filterBody(this.location.pathname.split('/')[1])}
        <div className="filter-container__pin-btn">
          <button type="button" className="btn btn-bright" data-dropdown="close" onClick={() => this.setState({ reset: !reset })}>Reset</button>
          <button type="button" className="btn btn-bright ml10 apply" data-dropdown="close" onClick={deleteModal}>Apply filters</button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isDe: state.user && state.user.profile_type === 'D',
});

const mapDispatchToProps = dispatch => ({
  deleteModal: () => dispatch(deleteAllModalComponent()),
});

const connectFilters = connect(mapStateToProps, mapDispatchToProps)(Filters);

connectFilters.displayName = 'Filters';

export default connectFilters;
