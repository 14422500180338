import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import { withRouter, withRouterMatch } from 'hocs';

import Icon from 'components/Icon';
import Filters from 'components/Filters';
import AvailabilityDropdown from 'components/AvailabilityDropdown';
import MobileMenuModal from 'components/MobileMenuModal';

import { ordersSetFavoriteFetch } from 'store/orders/actions';
import { addModalComponent } from 'store/modals/actions';

import timeConstants from 'constants/time';

import classNames from 'utils/classNames';

import './styles/index.scss';

const HEADERS_NAMES = {
  findorders: 'Find orders',
  myorders: 'My orders',
  finances: 'Finances',
  profile: 'Profile',
};

const HEADER_OPTIONS = {
  '/findorders/all': 'filter',
  '/findorders/all': 'filter',
  '/myorders/completed': 'filter',
  '/myorders/refunded': 'filter',
  '/profile': 'time',
};

let interval = null;

const Header = (props) => {
  const {
    location, match, orderNumber, history, isLoading, order, addModal, modals, isFilterApplied, available,
  } = props;
  const [diff, setDiffState] = useState(0);
  const [showLinksModal, setShowLinksModal] = useState(false);
  const path = location.pathname.split('/')[1];
  const getHeaderTitle = () => HEADERS_NAMES[path] || 'Find orders';

  const ref = useRef(false);

  const onClickUpScrollHandler = () => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

  const onClickFilterHandler = () => {
    addModal(<Filters location={location} />);
  };

  const toggleModal = () => {
    setShowLinksModal(it => !it);
  };

  const onClickTimeHandler = () => {
    addModal(<AvailabilityDropdown isMobile />);
  };

  const setDiff = () => {
    const diffCalc = !available ? 0 : moment(available).diff(moment());
    setDiffState(diffCalc);
  };

  useEffect(() => {
    const { ONE_MINUTE } = timeConstants;
    setDiff();
    interval = setInterval(setDiff, ONE_MINUTE);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (!ref.current) {
      ref.current = true;
      return;
    }
    const { ONE_MINUTE } = timeConstants;
    clearInterval(interval);
    setDiff();
    interval = setInterval(setDiff, ONE_MINUTE);
  }, [available]);

  const headerOption = HEADER_OPTIONS[location.pathname];

  const getHeaderOption = (option) => {
    const text = diff <= 0 ? '' : `Available ${moment.utc(diff).format('HH:mm')}`;
    switch (option) {
      case 'time':
        return (<div className={classNames('header__page-time header__page-icon', { 'header__page-time--with-text': !!text })} onClick={onClickTimeHandler}><Icon className="svg-icon" iconName="icon-time" /><span className="time-count">{text}</span></div>);
      case 'filter':
        return (<div className={classNames('header__page-filter header__page-icon', { 'header__page-filter--applied': isFilterApplied })} onClick={onClickFilterHandler}><Icon className="svg-icon" iconName="filters" /></div>);
      default:
        return null;
    }
  };

  if (modals.length) return null;

  if (path === 'order') {
    if (isLoading) return null;

    return (
      <div className="header header--order">
        <div className="header__page-title">{ orderNumber }</div>
        <button
          className="header__close-btn"
          onClick={() => {
            if (history.action === 'POP') history.replace('/');
            else history.goBack();
          }}
        >
          <Icon className="svg-icon" iconName="close" />
        </button>
      </div>
    );
  }

  return (
    <div className={classNames('header')}>
      <div className=" header__page-icon  header__page-icon--left" onClick={toggleModal}>
        <Icon className="svg-icon" iconName="hamburger" />
      </div>
      {showLinksModal && <MobileMenuModal toggleModal={toggleModal} />}
      <div className="header__page-title" onClick={onClickUpScrollHandler}>{getHeaderTitle()}</div>
      {getHeaderOption(headerOption)}
    </div>
  );
};

const mapStateToProps = (state, { location }) => {
  const path = location.pathname.split('/')[1];
  const { number = '' } = state.orderSingle;
  const order = state.orders[number] || {};
  const isLoading = state.orderSingle.isFetching || state.ordersFind.isFetching;
  const modals = state.modals.modalList;
  let isFilterApplied = false;
  if (path === 'findorders') {
    const filter = state.ordersFind.filter.all;
    const {
      time, subject = [], subject_group = [], type = [], statuses = [],
    } = filter;
    isFilterApplied = (time.length + subject.length + subject_group.length + type.length + statuses.length) > 0;
  } else if (path === 'myorders') {
    const filter = location.pathname === '/myorders/refunded' ? state.ordersRefunded.filter : state.ordersComplete.filter;
    const { time } = filter;
    isFilterApplied = time !== 'all';
  }
  const available = state.user.available_for;

  return {
    orderNumber: number,
    isLoading,
    order,
    modals,
    available,
    isFilterApplied,
  };
};

const mapDispatchToPropsOrder = dispatch => ({
  setFavorite: (value, orderNumber) => dispatch(ordersSetFavoriteFetch(orderNumber, value)),
  addModal: component => dispatch(addModalComponent(component)),
});

export default withRouterMatch(withRouter(connect(mapStateToProps, mapDispatchToPropsOrder)(Header)));

