import React from 'react';

import Checkbox from './Checkbox';

const CommonSearchForm = (props) => {
  const {
    mobile, orderTypes, checked, isDe = false, withDisabled = true, ...other
  } = props;
  return (
    <ul className={mobile ? '' : 'menu'}>
      {Object.keys(orderTypes).map((t) => {
        const item = orderTypes[t];
        return (<li key={t} className={mobile ? '' : 'menu-item'}>
          <Checkbox name={t} disabled={withDisabled ? !item.count : false} checked={checked.indexOf(t) > -1} {...other}>
            {t}
            {!!item.count && !isDe &&
              <span> ({item.count})</span>
            }
          </Checkbox>
        </li>)
      })}
    </ul>
  );
};


export default CommonSearchForm;
