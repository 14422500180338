import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import without from 'lodash/without';

import { getOrdersFilteredSortedCounts } from 'store/ordersFind/selectors';
import {
  resetAllFilters,
  ordersSetFilterMy,
  ordersSetFilterTime,
  ordersSetFilterStep,
  ordersFindSetFilterType,
  ordersFindSetFilterSubjectGroupWithSubjects,
} from 'store/ordersFind/actions';

import { useFirstRender } from 'hooks';

import Checkbox from 'components/Checkbox';

import storage from 'utils/localStorageHandler';

import { FILTER, TYPE_ORDERS } from './sharedConstants';
import { mapShortOrderTypes, getCntPreferSubj } from './helpers';
import DeadlinesFilter from './DeadlinesFilter';
import SubjectGroupsFilter from './SubjectGroupsFilter';
import TypesFilter from './TypesFilter';

import './styles/index.mobile.scss';


const OrdersMobileFilter = ({ reset = false, backReset }) => {
  const orderListCounts = useSelector(state => getOrdersFilteredSortedCounts[TYPE_ORDERS](state));
  const preferSubj = useSelector(state => state.user && state.user.subjects) || [];
  const orderFilteredListCnt = useSelector(state => state.ordersFilteredFind.numbers && state.ordersFilteredFind.numbers.length);
  const filter = useSelector(state => state.ordersFind.filter.all);
  const { orderSubjectGroups } = useSelector(state => state.settings);

  const orderTimes = (orderListCounts && orderListCounts.times) || [];
  const orderTypesNames = (orderListCounts && orderListCounts.types) || null;
  const orderTypes = mapShortOrderTypes(orderTypesNames) || {};
  const orderSubjectGroupsCount = (orderListCounts && orderListCounts.subjectGroups) || [];
  const orderSubjects = (orderListCounts && orderListCounts.subjects) || [];
  const cntPreferSubj = getCntPreferSubj(preferSubj, orderSubjectGroupsCount);
  const {
    time,
    subject,
    type,
    subject_group,
    steps,
    my,
  } = filter;

  const [stepFilter, setStepFilter] = useState(steps);
  const [stateFilter, setFilter] = useState({
    times: time || [],
    subjects: subject || [],
    types: type || [],
    subjectGroups: subject_group || [],
    steps: steps || [],
  });
  const dispatch = useDispatch();

  const setFilterType = value => dispatch(ordersFindSetFilterType(TYPE_ORDERS, value));
  const setFilterStep = value => dispatch(ordersSetFilterStep(TYPE_ORDERS, value));
  const setFilterTime = value => dispatch(ordersSetFilterTime(TYPE_ORDERS, value));
  const setFilterSubjectGroup = value => dispatch(ordersFindSetFilterSubjectGroupWithSubjects(TYPE_ORDERS, value));
  const resetFilters = () => dispatch(resetAllFilters(TYPE_ORDERS));
  const setFilterMy = value => dispatch(ordersSetFilterMy(TYPE_ORDERS, value));

  const onResetFilters = () => {
    resetFilters();
    const data = {
      times: [],
      subjects: [],
      types: [],
      subjectGroups: [],
      steps: [],
    };
    storage.set('filter', data);
    setFilter(data);
    setStepFilter([]);
    setFilterMy(false);
  };

  const getStepFilter = (field, arr) => {
    let arSteps = stepFilter;
    let isChange = false;
    if (arr.length > 0) {
      if ((stepFilter).indexOf(field) < 0) {
        arSteps.push(field);
        isChange = true;
      }
    }
    if (arr.length === 0) {
      if ((stepFilter).indexOf(field) > -1) {
        arSteps = arSteps.filter(s => s !== field);
        isChange = true;
      }
    }
    if (isChange) setFilterStep(arSteps);
    return arSteps;
  };

  const onFilterOrders = () => {
    let arSteps = stateFilter.steps;
    if (stateFilter.steps.indexOf(FILTER.subjects) === -1) {
      arSteps = [...stateFilter.steps, FILTER.subjects];
    } else if (stateFilter[FILTER.subjectGroups].length === 0) {
      arSteps = stateFilter.steps.filter(s => s !== FILTER.subjects);
    }
    const data = {
      ...stateFilter,
      steps: arSteps,
    };
    setFilter(data);
    setStepFilter(arSteps);
    storage.set('filter', data);
    getStepFilter(FILTER.subjects, data[FILTER.subjects]);
    setFilterSubjectGroup({
      subjectGroups: stateFilter[FILTER.subjectGroups],
      subjects: stateFilter[FILTER.subjects],
    });
  };

  const onChangeFilter = (field, value) => {
    let data = {};
    let arSteps = [];

    if (my) {
      setFilterMy(false);
    }

    switch (field) {
      case FILTER.subjectGroups:
        if (stateFilter[field].indexOf(value) > -1) {
          let sbj = stateFilter.subjects;

          stateFilter.subjects.map((s) => {
            if (orderSubjectGroups[value].indexOf(s) > -1) {
              sbj = sbj.filter(sb => sb !== s);
            }
          });
          data = {
            subjects: sbj,
            [field]: stateFilter[field].filter(item => item !== value),
          };
        } else {
          let sbj = [];
          orderSubjectGroups[value].map((s) => {
            if (orderSubjects[s].count > 0) {
              sbj = [...sbj, s];
            }
          });
          data = {
            subjects: [...stateFilter.subjects, ...sbj],
            [field]: [...stateFilter[field], value],
          };
        }
        break;

      case FILTER.times:
        if (stateFilter[field].indexOf(value) > -1) {
          data = {
            [field]: stateFilter[field].filter(item => item[0] !== value[0]),
          };
        } else {
          data = {
            [field]: [...stateFilter[field], value],
          };
        }
        break;
      case FILTER.types:
        if (stateFilter[field].indexOf(value[0]) > -1) {
          data = {
            [field]: without(stateFilter[field], ...value),
          };
        } else {
          data = {
            [field]: [...stateFilter[field], ...value],
          };
        }
        break;
      default:
        break;
    }

    if (field !== FILTER.subjectGroups && field !== FILTER.subjects) {
      arSteps = getStepFilter(field, data[field]);
      data.steps = arSteps;
    }

    data = {
      ...stateFilter,
      ...data,
    };

    setStepFilter(data.steps);
    if (field !== FILTER.subjectGroups && field !== FILTER.subjects) {
      storage.set('filter', data);
    }
    setFilter(data);

    switch (field) {
      case FILTER.types:
        setFilterType(data.types);
        break;
      case FILTER.times:
        setFilterTime(data.times);
        break;
      default:
        break;
    }
  };

  const onSetPreferSubj = () => {
    const _isPreferSubj = !my;
    const subjectsGroup = _isPreferSubj ? preferSubj : [];
    let subjectsList = [];
    if (_isPreferSubj) {
      preferSubj.map(s => subjectsList = [...orderSubjectGroups[s], ...subjectsList]);
    }
    const data = {
      ...stateFilter,
      subjectGroups: subjectsGroup,
      subjects: subjectsList,
    };

    setFilter(data);
    setFilterMy(_isPreferSubj);
  };

  const checkAndResetFilter = () => {
    if (orderFilteredListCnt === 0 && stateFilter.steps.length > 0) {
      const arSteps = stateFilter.steps;
      let newData = stateFilter;

      arSteps.slice(1).forEach((s) => {
        if (s === FILTER.subjects) {
          newData = {
            ...newData,
            [s]: [],
            subjectGroups: [],
          };
        } else {
          newData = {
            ...newData,
            [s]: [],
            subjectGroups: [],
          };
        }
        switch (s) {
          case FILTER.types:
            setFilterType(newData.types);
            break;
          case FILTER.times:
            setFilterTime(newData.times);
            break;
          default:
            break;
        }
      });

      newData = { ...newData, steps: arSteps.slice(0, 1) };
      setStepFilter([arSteps[0]]);
      setFilter(newData);
      storage.set('filter', newData);
    }
  };

  const isFirstRender = useFirstRender();

  useEffect(() => {
    if (!isFirstRender) onFilterOrders();
  }, [stateFilter.subjectGroups]);

  useEffect(() => {
    if (reset) {
      onResetFilters();
      backReset();
    }
  }, [reset]);

  useEffect(() => {
    checkAndResetFilter();
  }, [orderFilteredListCnt]);

  return (
    <div className="orders-filter-container">
      <ul className="container-item">
        <h3>Deadlines</h3>
        <DeadlinesFilter orderTimes={orderTimes} stateFilter={stateFilter} onChangeFilter={onChangeFilter} />
      </ul>
      <div className="container-item">
        <h3>Subjects</h3>
        <Checkbox name="My preferred subject" checked={my} onChange={onSetPreferSubj}>
          My preferred subject
          <span> ({cntPreferSubj || 0})</span>
        </Checkbox>
      </div>
      <ul className="container-item">
        <SubjectGroupsFilter orderSubjectGroupsCount={orderSubjectGroupsCount} stateFilter={stateFilter} orderSubjectGroups={orderSubjectGroups} onChangeFilter={onChangeFilter} isMobile />
      </ul>
      <ul className="container-item">
        <h3>Task types</h3>
        <TypesFilter orderTypes={orderTypes} stateFilter={stateFilter} onChangeFilter={onChangeFilter} />
      </ul>
    </div>
  );
};

export default OrdersMobileFilter;
