import React from 'react';

const NoFinances = () => (
  <div className="page-not-result">
    <span className="emoji" role="img" aria-label="">😭</span>
    <h1>Oops, there are no finance items matching this filter set.</h1>
    <p className="notes">Please try again with other filter configuration.</p>
  </div>
);

export default NoFinances;
