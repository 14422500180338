import React from 'react';
import { useSelector } from 'react-redux';

import { NavLink } from 'react-router-dom';

import {
  getHasUnreadMessages,
  getHasUnreadInboxItems,
  getHasUnreadFinances,
  getHasCntBonusesTask,
  getHasUnreadWarnings,
} from 'store/notification/selectors';

import Modal from 'components/Modal';
import Icon from 'components/Icon';

import utils from 'utils';
import classNames from 'utils/classNames';

import './styles/index.scss';

const MobileMenuModal = ({ toggleModal }) => {
  const hasNewInboxItems = useSelector(state => getHasUnreadInboxItems(state));
  const hasNewBonuses = useSelector(state => getHasCntBonusesTask(state));
  const hasUnreadWarnings = useSelector(state => getHasUnreadWarnings(state));
  const profile = useSelector(state => state.user);

  const inboxClassName = classNames({ new: hasNewInboxItems });
  const progressClassName = classNames({
    new: hasNewBonuses || hasUnreadWarnings,
    'new--usd': hasNewBonuses,
    'new--warnings': hasUnreadWarnings,
  });
  const isStem = utils.isStem(profile);
  const isDe = utils.isDe(profile);

  return (
    <Modal onClose={toggleModal} small className="mobile-menu">
      <h4 className="modal-info">Following sections are currently available in the desktop view only:</h4>
      <ul>
        {!isStem &&
          <li className="mobile-menu__item">
            <NavLink to="/inbox">
              <div className="mobile-menu__item--text inbox"><span className={inboxClassName}>Inbox</span></div>
            </NavLink>
          </li>
        }
        <li className="mobile-menu__item">
          <NavLink to="/progress">
            <div className="mobile-menu__item--text progress"><span className={progressClassName}>My Progress</span></div>
          </NavLink>
        </li>
        {!isDe &&
          <li className="mobile-menu__item">
            <NavLink to="/help/learninglab">
              <div className="mobile-menu__item--text learninglab">Learning Lab</div>
            </NavLink>
          </li>
        }
        <li className="mobile-menu__item">
          <NavLink to="/issues">
            <div className="mobile-menu__item--text issues">Issue Resolution</div>
          </NavLink>
        </li>
        <li className="mobile-menu__item">
          <NavLink to="/help">
            <div className="mobile-menu__item--text help">Help</div>
          </NavLink>
        </li>
      </ul>
      <div className="mobile-menu__footer-text">We will redirect you back to the mobile view automatically</div>
      <button className="btn btn-close" type="button" onClick={toggleModal}><Icon className="svg-icon" iconName="close" /></button>
    </Modal>
  );
};

export default MobileMenuModal;
