import React, { Component } from 'react';
import {
  NavLink,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import { connect } from 'react-redux';

import classNames from 'utils/classNames';

import { ordersProgressFetchIfNeeded } from 'store/ordersProgress/actions';
import { ordersRevisionFetchIfNeeded } from 'store/ordersRevision/actions';
import { ordersCompleteFetchIfNeeded } from 'store/ordersComplete/actions';
import { ordersRefundedFetchIfNeeded } from 'store/ordersRefunded/actions';

import {
  getHasUnreadMessagesOrdersProgress,
  getHasUnreadMessagesOrdersRevision,
  getHasUnreadMessagesOrdersComplete,
} from 'store/notification/selectors';

import Nav from 'components/Nav';
import MyOrdersCards from 'components/MyOrdersCards';

class MyOrdersPage extends Component {
  componentDidMount() {
    const { loadOrders } = this.props;
    loadOrders();
  }

  render() {
    const {
      hasUnreadProgress,
      hasUnreadRevision,
      hasUnreadComplete,
    } = this.props;
    const progressClassName = classNames({ new: hasUnreadProgress });
    const revisionClassName = classNames({ new: hasUnreadRevision });
    const completeClassName = classNames({ new: hasUnreadComplete });
    return (
      <div>
        <Nav sub subTop>
          <NavLink className={progressClassName} to="inprogress">In progress</NavLink>
          <NavLink className={revisionClassName} to="revisions">Revisions</NavLink>
          <NavLink className={completeClassName} to="completed">Completed</NavLink>
          <NavLink to="refunded">Refunded</NavLink>
        </Nav>
        <Routes>
          <Route path="inprogress" element={<MyOrdersCards />} />
          <Route path="revisions" element={<MyOrdersCards />} />
          <Route path="completed" element={<MyOrdersCards />} />
          <Route path="refunded" element={<MyOrdersCards />} />
          <Route path="*" element={<Navigate to="inprogress" replace />} />
        </Routes>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const hasUnreadProgress = getHasUnreadMessagesOrdersProgress(state);
  const hasUnreadRevision = getHasUnreadMessagesOrdersRevision(state);
  const hasUnreadComplete = getHasUnreadMessagesOrdersComplete(state);
  return { hasUnreadProgress, hasUnreadRevision, hasUnreadComplete };
};

const mapDispatchToProps = dispatch => ({
  loadOrders: () => {
    dispatch(ordersProgressFetchIfNeeded());
    dispatch(ordersRevisionFetchIfNeeded());
    dispatch(ordersCompleteFetchIfNeeded());
    dispatch(ordersRefundedFetchIfNeeded());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MyOrdersPage);
