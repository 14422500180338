import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withRouter } from 'hocs';

import convertTimeFilter from 'utils/convertTimeFilter';
import convertSorting from 'utils/convertSorting';

import OrderCard from 'components/OrderCard';
import CommonLoading from 'components/CommonLoading';
import NoOrders from 'components/NoOrders';

import { getOrdersProgressSorted } from 'store/ordersProgress/selectors';
import { getOrdersRevisionSorted } from 'store/ordersRevision/selectors';
import { getOrderList } from 'store/ordersComplete/selectors';
import { getOrderRefundList } from 'store/ordersRefunded/selectors';

import { ordersRefundedFetch } from 'store/ordersRefunded/actions';
import { ordersCompleteFetch } from 'store/ordersComplete/actions';

import { getOrderIdsWithUnreadMessages } from 'store/notification/selectors';

const MY_ORDERS_TYPES = {
  '/myorders/inprogress': {
    orderSelector: getOrdersProgressSorted,
  },
  '/myorders/revisions': {
    orderSelector: getOrdersRevisionSorted,
  },
  '/myorders/completed': {
    orderSelector: getOrderList,
    loading: 'ordersComplete',
    byPageLoading: true,
    loadData: ordersCompleteFetch,
  },
  '/myorders/refunded': {
    orderSelector: getOrderRefundList,
    loading: 'ordersRefunded',
    byPageLoading: true,
    loadData: ordersRefundedFetch,
  },
};

class MyOrdersCards extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.hasUnread = this.hasUnread.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.loadNextOrders = this.loadNextOrders.bind(this);
  }

  componentDidMount() {
    const { byPageLoading = false } = this.props;

    if (!byPageLoading) return;

    window.addEventListener('scroll', this.loadMore);
  }

  componentDidUpdate(prevProps) {
    const { byPageLoading: prevByPageLoading, filter: prevFilter = {}, sorting: prevSorting = {} } = prevProps;
    const { byPageLoading, filter = {}, sorting = {} } = this.props;

    if (JSON.stringify(prevFilter) !== JSON.stringify(filter) ||
        JSON.stringify(prevSorting) !== JSON.stringify(sorting)) {
      this.loadNextOrders(1);
    }

    if (prevByPageLoading && !byPageLoading) {
      window.removeEventListener('scroll', this.loadMore);
      return;
    }

    if (byPageLoading && !prevByPageLoading) {
      window.addEventListener('scroll', this.loadMore);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleMove);
  }

  onClick(id) {
    const { history } = this.props;
    history.push(`/order/${id}`);
  }

  loadMore() {
    const {
      isLoading, byPageLoading = false,
    } = this.props;
    if (isLoading || !byPageLoading) return;

    if (window.innerHeight + document.documentElement.scrollTop === document.scrollingElement.scrollHeight) {
      this.loadNextOrders();
    }
  }

  loadNextOrders(__page) {
    const {
      page, total, limit, filter, loadData, loadOrders, sorting,
    } = this.props;
    const nowLoadedOrders = page * limit;

    if (nowLoadedOrders >= total && !__page) return;

    if (!sorting || !filter) return;

    loadOrders(loadData, [__page || page + 1, convertSorting(sorting), new URLSearchParams(convertTimeFilter(filter)).toString()]);
  }

  hasUnread(orderId) {
    const { unreadOrderIds } = this.props;
    return unreadOrderIds.indexOf(orderId) > -1;
  }

  renderCard(order) {
    const { profile } = this.props;
    const { _id, number } = order;
    return (
      <OrderCard
        key={_id}
        myOrder
        number={number}
        profile={profile}
        hasUnReadMessage={this.hasUnread(_id)}
        onClick={id => this.onClick(id)}
      />
    );
  }

  renderOrderList() {
    const { orderList, isLoading, __location } = this.props;

    if (isLoading) {
      return (<CommonLoading />);
    }
    if (!orderList.length) {
      return (<NoOrders path={__location} />);
    }
    return orderList.map(order => this.renderCard(order));
  }

  render() {
    return (
      <div className="orders-cards my-orders">
        {this.renderOrderList()}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { location } = ownProps;
  let __location = location.pathname.split('/');
  const myOrderPathIndex = __location.indexOf('myorders');
  __location = `/${__location[myOrderPathIndex]}/${__location[myOrderPathIndex + 1] || ''}`;
  const orderType = MY_ORDERS_TYPES[__location] || MY_ORDERS_TYPES['/myorders/inprogress'];
  const orders = state.ordersProgress;

  return {
    isLoading: orderType.loading ? state[orderType.loading].isFetching : orders.isFetching,
    orderList: orderType.orderSelector(state),
    unreadOrderIds: getOrderIdsWithUnreadMessages(state),
    profile: state.user,
    __location,
    byPageLoading: orderType.byPageLoading || false,
    page: orderType.byPageLoading ? state[orderType.loading].page : null,
    total: orderType.byPageLoading ? state[orderType.loading].total : null,
    limit: orderType.byPageLoading ? state[orderType.loading].limit : null,
    filter: orderType.byPageLoading ? state[orderType.loading].filter : null,
    sorting: orderType.byPageLoading ? state[orderType.loading].sorting : null,
    loadData: orderType.byPageLoading ? orderType.loadData : null,
  };
};

const mapDispatchToProps = dispatch => ({
  loadOrders: (loadData, params) => dispatch(loadData(...params)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyOrdersCards));
