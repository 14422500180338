import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

import SubjectsSearchForm from 'components/SubjectsSearchForm.mobile';
import TypesSearchForm from 'components/TypesSearchForm';
import StatusesSearchForm from 'components/StatusesSearchForm';
import Radio from 'components/Radio';

import { ORDERS_TYPE_ALL, ORDERS_TYPE_RESERVED, ORDERS_TYPE_FAVORITE, resetAllFilters, ordersSetFilterTime } from 'store/ordersFind/actions';

import './styles/index.scss';

const ORDERS_TYPES = {
  '/findorders/all': ORDERS_TYPE_ALL,
  '/findorders/saved': ORDERS_TYPE_FAVORITE,
  '/findorders/reserved': ORDERS_TYPE_RESERVED,
};

class OrdersFilters extends Component {
  constructor(props) {
    super(props);
    this.orderType = ORDERS_TYPES[this.props.location.pathname] || ORDERS_TYPE_ALL;
    this.onTimeFilterChange = this.onTimeFilterChange.bind(this);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.reset !== this.props.reset) {
      this.props.resetFilters(this.orderType);
    }
  }
  onTimeFilterChange({ target }) {
    const { value } = target;
    const { setFilterTime } = this.props;
    setFilterTime(this.orderType, value);
  }
  render() {
    const { orderTimes, translate, filter } = this.props;
    return (
      <div className="orders-filter-container">
        <ul className="container-item">
          <h3>Deadlines</h3>
          {orderTimes.map(item => (
            <Radio key={item} name="deadlinesFilter" value={item} checked={!filter.time.length && item === 'all' ? true : filter.time.includes(item)} onChange={this.onTimeFilterChange}>
              {translate(`timeFilter.${item}`)}
            </Radio>
          ))}
        </ul>
        <ul className="container-item">
          <h3>Subjects</h3>
          <SubjectsSearchForm mobile typeId={this.orderType} isDe />
        </ul>
        <ul className="container-item">
          <h3>Task types</h3>
          <TypesSearchForm mobile typeId={this.orderType} isDe />
        </ul>
        <ul className="container-item">
          <h3>Task statuses</h3>
          <StatusesSearchForm mobile typeId={this.orderType} />
        </ul>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  orderTimes: state.settings.orderTimesDe,
  translate: getTranslate(state.locale),
  filter: state.ordersFind.filter[ORDERS_TYPE_ALL],
});


const mapDispatchToProps = dispatch => ({
  resetFilters: orderType => dispatch(resetAllFilters(orderType)),
  setFilterTime: (orderType, value) => dispatch(ordersSetFilterTime(orderType, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrdersFilters);
