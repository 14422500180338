import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import classNames from 'utils/classNames';
import utils from 'utils';
import storage from 'utils/localStorageHandler';

import { orderPrice } from 'components/order/utils';

import OrderDeadline from 'components/OrderDeadline';
import OrderTags from 'components/OrderTags';
import Icon from 'components/Icon';
import ReservedCounter from 'components/ReservedCounter';

import { getTakenOrderNotifications } from 'store/ordersNotifications/selectors';
import { removeTakenNotification, clearNewOrdersNotifications } from 'store/ordersNotifications/actions';
import {
  ordersSetStatusCheckFetch,
  ordersSetReservationOver,
  ordersHideInLists,
} from 'store/orders/actions';

import './styles/index.scss';

const OrderCard = ({
  order, profile, myOrder = false,
  hasUnReadMessage = false, isTaken,
  removeOrder, removeNewOrders,
  setReservationOver, checkReserve,
  isOtherWriterReserve = false,
  onClick,
}) => {
  const [orderReadyForRemove, setOrderForRemove] = useState(false);
  const [newOrderViewed, setNewOrderViewed] = useState(false);
  const [isOnViewPort, setIsOnViewPort] = useState(false);
  const [newOrderTimer, setNewOrderTimer] = useState(null);
  const [takenOrderTimer, setTakenOrderTimer] = useState(null);
  const orderItemEl = useRef(null);

  const onScroll = () => {
    const { scrollY, innerHeight } = window;

    if (!orderItemEl || !orderItemEl.current) return;

    const { offsetTop, offsetHeight } = orderItemEl.current;
    setIsOnViewPort((offsetTop > scrollY) && ((offsetTop + offsetHeight) < (scrollY + innerHeight)));
  };
  const onAnimationHandler = () => {
    if (!isTaken) return;
    removeOrder();
  };


  useEffect(() => {
    if (isTaken && !takenOrderTimer) {
      window.document.addEventListener('scroll', onScroll);
      onScroll();
    }
  }, [isTaken, takenOrderTimer]);

  useEffect(() => {
    if (isOtherWriterReserve) {
      removeOrder();
    }
  }, [isOtherWriterReserve]);

  useEffect(() => () => {
    window.document.removeEventListener('scroll', onScroll);
    if (orderItemEl && orderItemEl.current) {
      orderItemEl.current.removeEventListener('animationend', onAnimationHandler);
    }
    if (newOrderTimer) clearTimeout(newOrderTimer);
    if (takenOrderTimer) clearTimeout(takenOrderTimer);
  }, []);

  if (!order || !order._id) return null;

  const {
    _id, number,
    subject, type,
    writer_deadline,
    reserved_by_me,
    is_pinned,
    is_viewed,
    is_new_order: isNewOrder,
    writer_in_chat: writerInChat = false,
    current_writer_in_chat: currentWriterInChat,
  } = order;

  if (isOtherWriterReserve) {
    return null;
  }

  let rowClassName = '';
  const isStem = utils.isStem(profile);

  const onClickHandler = () => {
    if (isNewOrder) {
      removeNewOrders();
    }
    onClick(_id);

    if (is_viewed) {
      storage.set('userViewedCountChange', false);
      return;
    }

    const userViewedCount = storage.get('userViewedCount') || 0;
    if (userViewedCount < 0) return;
    storage.set('userViewedCount', userViewedCount + 1);
    storage.set('userViewedCountChange', true);
  };
  const onGotItClick = (event) => {
    checkReserve();
    event.stopPropagation();
    event.preventDefault();
  };

  const renderCounter = () => {
    if (order.isReservationOver) {
      return (
        <span className="text-warning">Reservation time is over. <span className="got-link" onClick={onGotItClick}>Got it.</span></span>
      );
    }
    return (
      <span className="my-reserve">
        Reserved: <ReservedCounter time={order.reserve_deadline} onOver={setReservationOver} />
      </span>
    );
  };

  const className = 'order-card';
  const isReservedByMe = reserved_by_me && !currentWriterInChat;

  if (isTaken) {
    rowClassName = classNames(
      className,
      {
        taken: isTaken,
        'taken--hide': orderReadyForRemove,
      },
    );
  } else {
    rowClassName = classNames(
      className,
      { 'reserve-by-me': isReservedByMe },
      { pinned: is_pinned },
      { 'not-read': !is_viewed },
      { 'new-order': isNewOrder },
      { 'new-order--with-animation': isNewOrder && !newOrderTimer },
      { 'new-order--viewed': isNewOrder && newOrderViewed },
    );
  }

  // if (!isTaken && isNewOrder && !newOrderTimer) {
  //   setTimeout(() => {
  //     setNewOrderTimer(setTimeout(() => {
  //       setNewOrderViewed(true);
  //     }, 5000));
  //   }, 1100);
  // }

  // if (isTaken && !takenOrderTimer && isOnViewPort) {
  //   setTakenOrderTimer(setTimeout(() => {
  //     setOrderForRemove(true);

  //     if (!orderItemEl || !orderItemEl.current) return;

  //     orderItemEl.current.addEventListener('animationend', onAnimationHandler);
  //   }, 2000));
  // }

  if (is_pinned) {
    const momentDeadLine = moment(writer_deadline);
    const hoursDiff = Math.round(momentDeadLine.diff(moment(), 'hours', true));
    const timeClassName = classNames({ 'text-warning': hoursDiff < 24 });
    return (
      <div className={rowClassName} ref={orderItemEl} onClick={onClickHandler}>
        <div className="order-card-header-block">
          <div className="order-card__sub">
            {is_pinned && <div className="pin-icon" />}
            <div className="text-ellipsis no-wrap">{ subject }</div>
          </div>
          <div className="row no-wrap center_center order-card-header-block__price-icons">
            <>
              {orderPrice({ order, profile })}
              { hoursDiff < 24 && <span className={timeClassName}> / {hoursDiff}h</span> }
            </>
            <OrderTags order={order} user={profile} callFromTable />
          </div>
        </div>
        {isReservedByMe &&
          <div className="order-card-tag-reserve-block">
            <div className="order-card-tag-reserve-block__reserve-time">{renderCounter()}</div>
          </div>
        }
      </div>
    );
  }

  return (
    <div className={rowClassName} ref={orderItemEl} onClick={onClickHandler}>
      <div className="order-card-header-block">
        <div className="order-card-header-block__type">{ type }</div>
        <div className="order-card-header-block__number fsMd">
          {hasUnReadMessage && myOrder &&
          <Icon className="svg-icon" iconName="email-notification" />
        }
          { number }
        </div>
      </div>
      <div className="order-card__sub">
        <div className="text-ellipsis no-wrap">{ subject }</div>
      </div>
      <div className="date-price-block">
        <div className="date-price-block__date-block fsMd">
          <Icon className="svg-icon" iconName="calendar2" />
          <div><OrderDeadline writer_deadline={writer_deadline} /></div>
        </div>
        <div>{orderPrice({ order, profile })}</div>
      </div>
      {((!myOrder && !isStem) || isReservedByMe) &&
        <div className="order-card-tag-reserve-block">
          <OrderTags order={order} user={profile} callFromTable isMobile />
          {isReservedByMe && <div className="order-card-tag-reserve-block__reserve-time">{renderCounter()}</div>}
        </div>
      }
    </div>
  );
};

const mapStateToProps = (state, { number }) => {
  const order = state.orders[number];
  const profile = state.user;
  const isNewOrder = order && order.is_new_order;
  const isTaken = getTakenOrderNotifications(state).includes(order && order.number);
  return {
    order, profile, isNewOrder, isTaken,
  };
};

const mapDispatchToProps = (dispatch, { number }) => ({
  checkReserve: () => dispatch(ordersSetStatusCheckFetch(number)),
  setReservationOver: () => {
    dispatch(ordersSetReservationOver(number));
  },
  removeOrder: () => {
    dispatch(ordersHideInLists(number));
    dispatch(removeTakenNotification(number));
  },
  removeNewOrders: () => {
    dispatch(clearNewOrdersNotifications());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderCard);
