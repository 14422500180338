import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import utils from 'utils';

import isValidProfile from 'utils/isValidProfile';

import { getProfile, getAgreementInfo } from 'store/user/selectors';

import './styles/index.mobile.scss';

const NotFoundPage = () => {
  const profile = useSelector(getProfile);
  const showAgreement = useSelector(getAgreementInfo);

  const __isValidProfile = isValidProfile(profile);

  if (showAgreement || !__isValidProfile) return null;

  useEffect(() => {
    utils.updateCookie('DESKTOP', 'DESKTOP', 1000);
    setTimeout(() => {
      window.location.reload(true);
    }, 100);
  }, []);

  return (
    <div className="not-container">
      <div className="not-container__text">Redirect to desktop version ...</div>
    </div>
  );
};

export default NotFoundPage;
