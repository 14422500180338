import React from 'react';
import { connect } from 'react-redux';

import { ordersFindSetFilterType } from './../store/ordersFind/actions';
import { getOrdersFind, getOrdersTypesWithCount } from './../store/ordersFind/selectors';

import CommonSearchForm from './CommonSearchForm';

const TypesSearchForm = (props) => {
  const {
    filter, orderTypes, setFilterType, mobile, isDe = false,
  } = props;
  const onChangeTypeFilter = ({ target }) => {
    const { checked, name: value } = target;
    const newData = checked ? [...filter.type, value] : filter.type.filter(t => t !== value);
    setFilterType(newData);
  };
  return (
    <CommonSearchForm
      mobile={mobile}
      orderTypes={orderTypes}
      onChange={onChangeTypeFilter}
      checked={filter.type}
      isDe={isDe}
      withDisabled={false}
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  const filter = state.ordersFind.filter[ownProps.typeId];
  const orderTypes = getOrdersTypesWithCount[ownProps.typeId](state, ownProps);
  const orders = getOrdersFind[ownProps.typeId](state);
  return { filter, orderTypes, orders };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  setFilterType: value => dispatch(ordersFindSetFilterType(ownProps.typeId, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TypesSearchForm);
