import React from 'react';
import { useSelector } from 'react-redux';

import isValidProfile from 'utils/isValidProfile';

import { getProfile, getAgreementInfo } from 'store/user/selectors';

import './styles/index.mobile.scss';

const NotConditionUserPage = () => {
  const showAgreement = useSelector(getAgreementInfo);
  const profile = useSelector(getProfile);

  const __isValidProfile = isValidProfile(profile);

  if (!showAgreement && __isValidProfile) return null;

  document.documentElement.classList.add('no-scroll');

  return (
    <div className="not-container">
      <div className="not-container__text">You need to submit required information using desktop version of the application. Afterwards you will be able to use mobile version of the application</div>
    </div>
  );
};

export default NotConditionUserPage;
